<template>
  <DefaultTabs
    v-model="currentTab"
    grow
    flat
    class="mt-4"
    slider-color="primary"
    arrowMargins="30px"
    :hide-slider="true"
    show-arrows
  >
    <v-tab
      v-for="(session, index) in sessions"
      :ripple="false"
      class="text-subtitle-1 font-weight-bold"
      :key="index"
      :value="index"
    >
      <v-layout shrink class="text-center" justify-start align-center>
        <DefaultButton
          icon
          :bgColor="session.active ? 'primary' : 'secondary'"
          class="text-h6 pa-0"
          >{{ session.active ? "A" : "Z" }}</DefaultButton
        >
        <v-layout column shrink class="ml-2">
          <DefaultTitle
            :color="session.active ? 'primary' : 'secondary'"
          >
            {{ session.name }}
          </DefaultTitle>
          <v-flex v-if="sessions[value].id == session.id">
            <div :class="['zg-pc-line', session.active && 'active']"></div>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-tab>
  </DefaultTabs>
</template>

<script>
export default {
  props: {
    value: {},
    sessions: {},
  },
  computed: {
    currentTab: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    }
  },
  components: {
    DefaultTabs: () => import("@/components/tabs/DefaultTabs"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultTitle: () => import("@/components/text/DefaultTitle"),
  }
};
</script>

<style scoped lang="scss">
.zg-pc-line {
  background-color: var(--v-secondary-base);
  margin-top: 8px;
  height: 3px;
  width: 100%;

  &.active {
    background-color: var(--v-primary-base);
  }
}
</style>
